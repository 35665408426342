<template>
  <div class="about justify-content-center">
    <navbar v-if="category != 'building'"></navbar>
    <navbar-building v-else ></navbar-building>
    <div class="empty">

    </div>
    <div class="d-flex justify-content-center">
      <div class="col-10 ">
        <modir-amel></modir-amel>
      </div>
    </div>
    <div class="empty"></div>
    <div class="col-12  d-flex justify-content-center">
      <div>
        <about-us :border="border" :bn="bn" class="p-4">
          <template slot="text">
            <div ref="about">
            </div>
          </template>
        </about-us>
      </div>
    </div>
    <div class="empty">

    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import NavbarBuilding from '@/components/NavbarBuilding';
import About from '../components/About.vue'
import ModirAmel from '@/components/ModirAmel';
import httpClient from '../services/http.service';
import Footer from '../components/Footer';
export default {
  name:'About',
  props: ['menu'],
  data:function(){
      return{
          category : this.$route.params.category,
          border:null,
          bn:null,
          txt:null,
      }
  },
  methods: {
    async sincData() {
      try {
        const{status,data} = await httpClient.get(`${this.$route.params.language}/categories`,{params:{title:this.category}});
        if (status == 200) {
          this.$refs.about.innerHTML = '<p class="about-text mx-3 f-20 my-3">'+data[0].data.about+'</p>';
          this.txt = data[0].data.about;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  components: {
    'navbar':Navbar,
    'modir-amel':ModirAmel,
    'about-us':About,
    NavbarBuilding,
    Footer,
  },
  mounted(){
    if(this.$route.params.category == 'plast'){
        this.border = 'border-plast';
        this.bn = 'bnb';
    }else if(this.$route.params.category == 'tajhiz'){
        this.border = 'border-tajhiz';
        this.bn = 'bnb';
    }else if(this.$route.params.category == 'building'){
      this.border = 'border-building';
      this.bn = 'bnb';
    }
    this.sincData();
  },
}
</script>

<style lang="css">
body{
  overflow-y: visible;
}
.bg-op{
  width: 90%;
  padding-top: 3em;
  background: linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
}
.f-20{
  font-size: 20px;
  text-align: justify;
}
</style>
