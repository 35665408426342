<template>
    <div>
  <b-navbar toggleable="lg" type="dark">
    <b-navbar-brand href="#"><img src="/img/1.png" alt="azarpajoohesh" width="100px"></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :href="'/'+$i18n.locale" class="nav-item" :class="border">{{ $t("menu.index") }}</b-nav-item>
        <b-nav-item :href="'/'+$i18n.locale+'/'+category" class="nav-item" :class="border">{{ $t("menu.home") }}</b-nav-item>
        <b-nav-item :href="'/'+$i18n.locale+'/'+category+'/about'" class="nav-item" :class="border">{{ $t("menu.about") }}</b-nav-item>
        <b-nav-item :href="'/'+$i18n.locale+'/'+category+'/contact'" class="nav-item" :class="border">{{ $t("menu.contact") }}</b-nav-item>
        <b-nav-item :href="'/'+$i18n.locale+'/'+category+'/projects'" class="nav-item" :class="border">{{ $t("menu.projects") }}</b-nav-item>
        <b-nav-item :href="'/'+$i18n.locale+'/'+category+'/ads'" class="nav-item" :class="border">
          {{ $t("menu.ads") }}
        </b-nav-item>
        <b-nav-item :href="'/'+$i18n.locale+'/tomorrow'" class="nav-item" :class="border">{{ $t("menu.tomorrow") }}</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import httpClient from '@/services/http.service';
    export default {
        name:'Navbar',
        data(){
          return {
            border:'building-border',
            childBorder:'has-child-building',
            parentCategory : null,
            category:'building',
          };
        },
        async mounted() {
          //do something after mounting vue instance
          try {
                const {status , data} = await httpClient.get('/fa/categories',{params:{
                  title:this.$route.params.category,
                  expand:'childs,'
                }})
                if (status == 200) {
                  if(data[0].parent != null){
                    this.parentCategory = data[0].parentCategory.title;
                  }else{
                    this.category = data[0].title;
                  }
                  
                }
              } catch (e) {
                this.errors = 'Conection Is Faild';
              }
        },
    }
</script>

<style lang="scss" >
    .navbar-dark .navbar-nav .nav-link{
        color: #fff;
        margin-right: 3em;
    }
    .nav-link{
      border-bottom: 2px solid transparent;
       transition: border-bottom 0.5s;
    }
    .has-child .nav-link{
      border-bottom: none;
    }
    .child-nav .nav-link{
      border-bottom:none;
    }
    .building-border:hover .nav-link{
      border-image-source: linear-gradient(45deg, #000000 0%,#ff0000 100%);
      border-image-slice: 1;
      border-bottom: 2px solid;
    }
    .has-child:hover .child-nav{
      display: block;
    }
    .has-child-building:hover .nav-link,.has-child-plast:hover .child-nav{
      border-image-source: linear-gradient(45deg, #000000 0%,#ff0000 100%);
      border-image-slice: 1;
      border-right: 2px solid;
    }
    .has-child-plast:hover .nav-link.link{
      border-right: none;
    }
    .child-nav{
      display: none;
      position: absolute;
      margin-right:-10px;
      padding-right: 7px;
      padding-top: 10px;
    }
    .link{
      font-size: 15px !important;
      padding-top: 15px;
      position: relative;
      display: block;
    }
    .nav-link{
      font-size: 16px;
    }
    .child-nav .nav-item::marker{
      content: '';
    }
    .child-nav .nav-item .nav-link{
      margin-right: 0;
    }
</style>
